@import '@fontsource/roboto-condensed/700.css';
@font-face {
  font-family: "Avenir";
  src: url("/assets/fonts/avenir-roman-400-webfont.woff2") format("woff2"), url("/assets/fonts/avenir-roman-400-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Avenir";
  src: url("/assets/fonts/avenir-medium-500-webfont.woff2") format("woff2"), url("/assets/fonts/avenir-medium-500-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Avenir";
  src: url("/assets/fonts/avenir-heavy-900-webfont.woff2") format("woff2"), url("/assets/fonts/avenir-heavy-900-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Tiempos Headline";
  src: url("/assets/fonts/tiemposheadline-semibold-700-webfont.woff2") format("woff2"), url("/assets/fonts/tiemposheadline-semibold-700-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
:root {
  --c-red-1: #ff5a60;
  --c-red-2: #ff7b80;
  --c-red-3: #ffadb0;
  --c-red-4: #ffdedf;
  --c-red-5: #fdf0f0;
  --c-blue-1: #020535;
  --c-blue-2: #0070e0;
  --c-blue-3: #0583ff;
  --c-blue-4: #379cff;
  --c-blue-5: #82c1ff;
  --c-blue-6: #d0e6fd;
  --c-blue-7: #0b4fbd;
  --c-green-1: #75d002;
  --c-green-2: #91d935;
  --c-green-3: #bae880;
  --c-green-4: #e3f6cc;
  --c-purple-1: #894beb;
  --c-purple-2: #a06fef;
  --c-purple-3: #c4a5f5;
  --c-purple-4: #e7dbfb;
  --c-teal-1: #2ab8c3;
  --c-teal-2: #55c6cf;
  --c-teal-3: #95dce1;
  --c-teal-4: #d4f1f3;
  --c-gray-0: #121212;
  --c-gray-1: #26292f;
  --c-gray-2: #313235;
  --c-gray-3: #44454b;
  --c-gray-4: #50515b;
  --c-gray-5: #72727b;
  --c-gray-6: #cccfd4;
  --c-gray-7: #e6e6ec;
  --c-gray-8: #f5f7fa;
  --c-gray-9: #fbfdff;
  --c-gray-10: #f7f7f7;
  --c-primary: #ff5a60;
  --c-accent: #0070e0;
  --c-white: #ffffff;
  --c-black: #000000;
  --c-success: #579900;
  --c-warning: #bb573e;
  --c-warning-yellow: #ffd952;
  --c-error: #d62b31;
  --c-page-bg: var(--c-white);
  --c-toolbar-bg: var(--c-white);
  --c-right-drawer-bg: var(--c-white);
  --c-border: var(--c-gray-6);
  --c-border-light: var(--c-gray-7);
  --c-disabled: var(--c-gray-7);
  --c-overlay-bg: rgba(255, 255, 255, 0.8);
  --c-text-base: var(--c-gray-3);
  --c-text-heading: var(--c-gray-1);
  --c-text-heading-secondary: var(--c-gray-2);
  --c-text-label: var(--c-gray-1);
  --c-text-input: var(--c-gray-1);
  --c-text-primary-contrast: var(--c-white);
  --ff-heading: Avenir, Helvetica, Arial, sans-serif;
  --ff-base: Avenir, Helvetica, Arial, sans-serif;
  --ff-marketing-heading: Tiempos Headline, Helvetica, Arial, sans-serif;
  --ff-special: Roboto Condensed, Avenir, Helvetica, Arial, sans-serif;
  --fw-normal: 400;
  --fw-medium: 500;
  --fw-semibold: 700;
  --fw-bold: 900;
  --fs-1: 12px;
  --fs-2: 14px;
  --fs-3: 16px;
  --fs-4: 18px;
  --fs-5: 20px;
  --fs-h1: 28px;
  --fs-h2: 22px;
  --fs-h3: 16px;
  --fs-h4: 16px;
  --fs-h5: 16px;
  --fs-base: var(--fs-2);
  --s-1: 4px;
  --s-2: 8px;
  --s-3: 12px;
  --s-4: 16px;
  --s-5: 24px;
  --s-6: 32px;
  --s-6-5: 42px;
  --s-7: 48px;
  --s-8: 54px;
  --s-top-bar-height: 50px;
  --s-left-drawer-width: 212px;
  --s-right-toolbar-width: 48px;
  --s-right-drawer-width: 240px;
  --s-whiteboard-toolbar-height: 44px;
  --s-drawer-header-height: 54px;
  --s-drawer-subheader-height: 48px;
  --standard-transition-speed: 280ms;
}
:root .dark-theme {
  --c-primary: #82c1ff;
  --c-page-bg: var(--c-gray-0);
  --c-toolbar-bg: var(--c-gray-2);
  --c-right-drawer-bg: var(--c-gray-1);
  --c-border: var(--c-gray-3);
  --c-border-light: var(--c-gray-2);
  --c-disabled: var(--c-gray-4);
  --c-overlay-bg: rgba(0, 0, 0, 0.6);
  --c-text-base: var(--c-gray-6);
  --c-text-heading: var(--c-white);
  --c-text-heading-secondary: var(--c-gray-9);
  --c-text-label: var(--c-white);
  --c-text-input: var(--c-white);
  --c-text-primary-contrast: var(--c-gray-3);
}

html {
  font-family: var(--ff-base);
  color: var(--c-text-base);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--c-page-bg);
}

body {
  font-family: var(--ff-base) !important;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}
*:not(text),
*:before:not(text),
*:after:not(text) {
  font-family: inherit;
}

.heading-1 {
  color: var(--c-text-heading);
  font-family: var(--ff-heading);
  font-weight: var(--fw-medium);
  font-size: var(--fs-h1);
  margin: 0;
  letter-spacing: 0.25px;
  line-height: 32px;
}

.heading-2 {
  color: var(--c-text-heading);
  font-family: var(--ff-heading);
  font-weight: var(--fw-medium);
  font-size: var(--fs-h2);
  margin: 0;
  letter-spacing: 0.25px;
  line-height: 28px;
}

.heading-3 {
  color: var(--c-text-heading);
  font-family: var(--ff-heading);
  font-weight: var(--fw-medium);
  font-size: var(--fs-h3);
  margin: 0;
  letter-spacing: 0.25px;
  line-height: 24px;
}

.heading-4 {
  color: var(--c-text-heading);
  font-family: var(--ff-heading);
  font-weight: var(--fw-bold);
  font-size: var(--fs-h4);
  margin: 0;
  letter-spacing: 2px;
  line-height: 30px;
}

.heading-5 {
  color: var(--c-text-heading);
  font-family: var(--ff-heading);
  font-weight: var(--fw-medium);
  font-size: var(--fs-h5);
  margin: 0;
  letter-spacing: 2px;
  line-height: 30px;
}

.subheading {
  color: var(--c-text-heading);
  font-family: var(--ff-heading);
  font-weight: var(--fw-normal);
  font-size: var(--fs-2);
  margin: 0;
  line-height: 20px;
}

[role=button]:not([disabled]) {
  cursor: pointer;
}

::selection,
::-moz-selection {
  color: #44454b !important;
  background: #d0e6fd !important;
}

.dark-theme ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.dark-theme ::-webkit-scrollbar-track {
  background-color: #26292f;
  border-left: 1px solid #50515b;
}
.dark-theme ::-webkit-scrollbar-thumb {
  background-color: #cccfd4;
  border-radius: 15px;
  border: 2px solid rgba(0, 0, 0, 0);
  border-left-width: 3px;
  background-clip: padding-box;
}
@supports (scrollbar-color: #cccfd4 #26292f) {
  .dark-theme * {
    scrollbar-color: #cccfd4 #26292f;
    scrollbar-width: thin;
  }
}

.fade-in {
  animation: 280ms linear fadeInTransition;
}

@keyframes fadeInTransition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.s-1 {
  width: var(--s-1);
  height: var(--s-1);
}

.s-2 {
  width: var(--s-2);
  height: var(--s-2);
}

.s-3 {
  width: var(--s-3);
  height: var(--s-3);
}

.s-4 {
  width: var(--s-4);
  height: var(--s-4);
}

.s-5 {
  width: var(--s-5);
  height: var(--s-5);
}

.s-6 {
  width: var(--s-6);
  height: var(--s-6);
}

.s-7 {
  width: var(--s-7);
  height: var(--s-7);
}

.s-8 {
  width: var(--s-8);
  height: var(--s-8);
}

.m-1 {
  margin: var(--s-1);
}

.m-2 {
  margin: var(--s-2);
}

.m-3 {
  margin: var(--s-3);
}

.m-4 {
  margin: var(--s-4);
}

.m-5 {
  margin: var(--s-5);
}

.m-6 {
  margin: var(--s-6);
}

.m-7 {
  margin: var(--s-7);
}

.m-8 {
  margin: var(--s-8);
}

.mt-1 {
  margin-top: var(--s-1);
}

.mt-2 {
  margin-top: var(--s-2);
}

.mt-3 {
  margin-top: var(--s-3);
}

.mt-4 {
  margin-top: var(--s-4);
}

.mt-5 {
  margin-top: var(--s-5);
}

.mt-6 {
  margin-top: var(--s-6);
}

.mt-7 {
  margin-top: var(--s-7);
}

.mt-8 {
  margin-top: var(--s-8);
}

.mr-1 {
  margin-right: var(--s-1);
}

.mr-2 {
  margin-right: var(--s-2);
}

.mr-3 {
  margin-right: var(--s-3);
}

.mr-4 {
  margin-right: var(--s-4);
}

.mr-5 {
  margin-right: var(--s-5);
}

.mr-6 {
  margin-right: var(--s-6);
}

.mr-7 {
  margin-right: var(--s-7);
}

.mr-8 {
  margin-right: var(--s-8);
}

.mb-1 {
  margin-bottom: var(--s-1);
}

.mb-2 {
  margin-bottom: var(--s-2);
}

.mb-3 {
  margin-bottom: var(--s-3);
}

.mb-4 {
  margin-bottom: var(--s-4);
}

.mb-5 {
  margin-bottom: var(--s-5);
}

.mb-6 {
  margin-bottom: var(--s-6);
}

.mb-7 {
  margin-bottom: var(--s-7);
}

.mb-8 {
  margin-bottom: var(--s-8);
}

.ml-1 {
  margin-left: var(--s-1);
}

.ml-2 {
  margin-left: var(--s-2);
}

.ml-3 {
  margin-left: var(--s-3);
}

.ml-4 {
  margin-left: var(--s-4);
}

.ml-5 {
  margin-left: var(--s-5);
}

.ml-6 {
  margin-left: var(--s-6);
}

.ml-7 {
  margin-left: var(--s-7);
}

.ml-8 {
  margin-left: var(--s-8);
}

.p-1 {
  padding: var(--s-1);
}

.p-2 {
  padding: var(--s-2);
}

.p-3 {
  padding: var(--s-3);
}

.p-4 {
  padding: var(--s-4);
}

.p-5 {
  padding: var(--s-5);
}

.p-6 {
  padding: var(--s-6);
}

.p-7 {
  padding: var(--s-7);
}

.p-8 {
  padding: var(--s-8);
}

.pt-1 {
  padding-top: var(--s-1);
}

.pt-2 {
  padding-top: var(--s-2);
}

.pt-3 {
  padding-top: var(--s-3);
}

.pt-4 {
  padding-top: var(--s-4);
}

.pt-5 {
  padding-top: var(--s-5);
}

.pt-6 {
  padding-top: var(--s-6);
}

.pt-7 {
  padding-top: var(--s-7);
}

.pt-8 {
  padding-top: var(--s-8);
}

.pr-1 {
  padding-right: var(--s-1);
}

.pr-2 {
  padding-right: var(--s-2);
}

.pr-3 {
  padding-right: var(--s-3);
}

.pr-4 {
  padding-right: var(--s-4);
}

.pr-5 {
  padding-right: var(--s-5);
}

.pr-6 {
  padding-right: var(--s-6);
}

.pr-7 {
  padding-right: var(--s-7);
}

.pr-8 {
  padding-right: var(--s-8);
}

.pb-1 {
  padding-bottom: var(--s-1);
}

.pb-2 {
  padding-bottom: var(--s-2);
}

.pb-3 {
  padding-bottom: var(--s-3);
}

.pb-4 {
  padding-bottom: var(--s-4);
}

.pb-5 {
  padding-bottom: var(--s-5);
}

.pb-6 {
  padding-bottom: var(--s-6);
}

.pb-7 {
  padding-bottom: var(--s-7);
}

.pb-8 {
  padding-bottom: var(--s-8);
}

.pl-1 {
  padding-left: var(--s-1);
}

.pl-2 {
  padding-left: var(--s-2);
}

.pl-3 {
  padding-left: var(--s-3);
}

.pl-4 {
  padding-left: var(--s-4);
}

.pl-5 {
  padding-left: var(--s-5);
}

.pl-6 {
  padding-left: var(--s-6);
}

.pl-7 {
  padding-left: var(--s-7);
}

.pl-8 {
  padding-left: var(--s-8);
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

html {
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, sans-serif;
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 16px / 24px Roboto, sans-serif;
  letter-spacing: 0.03125em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 22px Roboto, sans-serif;
  letter-spacing: 0.0071428571em;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px Roboto, sans-serif;
  letter-spacing: 0.0178571429em;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, sans-serif;
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 96px / 96px Roboto, sans-serif;
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 60px / 60px Roboto, sans-serif;
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 48px / 50px Roboto, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Roboto, sans-serif;
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-title-text-font: Roboto, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-node-text-font: Roboto, sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-card-title-text-font: Roboto, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Roboto, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}

html {
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #ff5a60;
  --mat-option-label-text-color: #44454b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #0070e0;
  --mat-option-label-text-color: #44454b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #bb573e;
  --mat-option-label-text-color: #44454b;
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: #44454b;
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #ff5a60;
  --mat-full-pseudo-checkbox-selected-checkmark-color: white;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: white;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #ff5a60;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #0070e0;
  --mat-full-pseudo-checkbox-selected-checkmark-color: white;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: white;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #0070e0;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #0070e0;
  --mat-full-pseudo-checkbox-selected-checkmark-color: white;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: white;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #0070e0;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #bb573e;
  --mat-full-pseudo-checkbox-selected-checkmark-color: white;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: white;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #bb573e;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: white;
  --mat-app-text-color: #44454b;
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #ff5a60;
  --mdc-linear-progress-track-color: rgba(255, 90, 96, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #0070e0;
  --mdc-linear-progress-track-color: rgba(0, 112, 224, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #bb573e;
  --mdc-linear-progress-track-color: rgba(187, 87, 62, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #ff5a60;
  --mdc-filled-text-field-focus-active-indicator-color: #ff5a60;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 90, 96, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(68, 69, 75, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(68, 69, 75, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(68, 69, 75, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(68, 69, 75, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #bb573e;
  --mdc-filled-text-field-error-focus-label-text-color: #bb573e;
  --mdc-filled-text-field-error-label-text-color: #bb573e;
  --mdc-filled-text-field-error-caret-color: #bb573e;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #bb573e;
  --mdc-filled-text-field-error-focus-active-indicator-color: #bb573e;
  --mdc-filled-text-field-error-hover-active-indicator-color: #bb573e;
  --mdc-outlined-text-field-caret-color: #ff5a60;
  --mdc-outlined-text-field-focus-outline-color: #ff5a60;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 90, 96, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(68, 69, 75, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(68, 69, 75, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(68, 69, 75, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(68, 69, 75, 0.6);
  --mdc-outlined-text-field-error-caret-color: #bb573e;
  --mdc-outlined-text-field-error-focus-label-text-color: #bb573e;
  --mdc-outlined-text-field-error-label-text-color: #bb573e;
  --mdc-outlined-text-field-error-hover-label-text-color: #bb573e;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #bb573e;
  --mdc-outlined-text-field-error-hover-outline-color: #bb573e;
  --mdc-outlined-text-field-error-outline-color: #bb573e;
  --mat-form-field-focus-select-arrow-color: rgba(255, 90, 96, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #bb573e;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #0070e0;
  --mdc-filled-text-field-focus-active-indicator-color: #0070e0;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 112, 224, 0.87);
  --mdc-outlined-text-field-caret-color: #0070e0;
  --mdc-outlined-text-field-focus-outline-color: #0070e0;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 112, 224, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(0, 112, 224, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #bb573e;
  --mdc-filled-text-field-focus-active-indicator-color: #bb573e;
  --mdc-filled-text-field-focus-label-text-color: rgba(187, 87, 62, 0.87);
  --mdc-outlined-text-field-caret-color: #bb573e;
  --mdc-outlined-text-field-focus-outline-color: #bb573e;
  --mdc-outlined-text-field-focus-label-text-color: rgba(187, 87, 62, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(187, 87, 62, 0.87);
}

html {
  --mat-form-field-container-height: 48px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 12px;
  --mat-form-field-filled-with-label-container-padding-top: 12px;
  --mat-form-field-filled-with-label-container-padding-bottom: 12px;
}

html {
  --mdc-filled-text-field-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(68, 69, 75, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(68, 69, 75, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(68, 69, 75, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 90, 96, 0.87);
  --mat-select-invalid-arrow-color: rgba(187, 87, 62, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(68, 69, 75, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(68, 69, 75, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(68, 69, 75, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 112, 224, 0.87);
  --mat-select-invalid-arrow-color: rgba(187, 87, 62, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(68, 69, 75, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(68, 69, 75, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(68, 69, 75, 0.38);
  --mat-select-focused-arrow-color: rgba(187, 87, 62, 0.87);
  --mat-select-invalid-arrow-color: rgba(187, 87, 62, 0.87);
}

html {
  --mat-select-arrow-transform: none;
}

html {
  --mat-select-trigger-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(68, 69, 75, 0.87);
  --mdc-dialog-supporting-text-color: rgba(68, 69, 75, 0.6);
}

html {
  --mdc-dialog-subhead-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #ff5a60;
  --mdc-chip-elevated-selected-container-color: #ff5a60;
  --mdc-chip-elevated-disabled-container-color: #ff5a60;
  --mdc-chip-flat-disabled-selected-container-color: #ff5a60;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #0070e0;
  --mdc-chip-elevated-selected-container-color: #0070e0;
  --mdc-chip-elevated-disabled-container-color: #0070e0;
  --mdc-chip-flat-disabled-selected-container-color: #0070e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #bb573e;
  --mdc-chip-elevated-selected-container-color: #bb573e;
  --mdc-chip-elevated-disabled-container-color: #bb573e;
  --mdc-chip-flat-disabled-selected-container-color: #bb573e;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 24px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #ff5258;
  --mdc-switch-selected-handle-color: #ff5258;
  --mdc-switch-selected-hover-state-layer-color: #ff5258;
  --mdc-switch-selected-pressed-state-layer-color: #ff5258;
  --mdc-switch-selected-focus-track-color: #ff8c90;
  --mdc-switch-selected-hover-track-color: #ff8c90;
  --mdc-switch-selected-pressed-track-color: #ff8c90;
  --mdc-switch-selected-track-color: #ff8c90;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: #44454b;
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #0068dc;
  --mdc-switch-selected-handle-color: #0068dc;
  --mdc-switch-selected-hover-state-layer-color: #0068dc;
  --mdc-switch-selected-pressed-state-layer-color: #0068dc;
  --mdc-switch-selected-focus-track-color: #4d9be9;
  --mdc-switch-selected-hover-track-color: #4d9be9;
  --mdc-switch-selected-pressed-track-color: #4d9be9;
  --mdc-switch-selected-track-color: #4d9be9;
}
html {
  --mdc-switch-state-layer-size: 32px;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: #44454b;
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff5a60;
  --mdc-radio-selected-hover-icon-color: #ff5a60;
  --mdc-radio-selected-icon-color: #ff5a60;
  --mdc-radio-selected-pressed-icon-color: #ff5a60;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #ff5a60;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0070e0;
  --mdc-radio-selected-hover-icon-color: #0070e0;
  --mdc-radio-selected-icon-color: #0070e0;
  --mdc-radio-selected-pressed-icon-color: #0070e0;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #0070e0;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #bb573e;
  --mdc-radio-selected-hover-icon-color: #bb573e;
  --mdc-radio-selected-icon-color: #bb573e;
  --mdc-radio-selected-pressed-icon-color: #bb573e;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #bb573e;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-radio-state-layer-size: 32px;
  --mat-radio-touch-target-display: none;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: #ff5a60;
  --mdc-slider-focus-handle-color: #ff5a60;
  --mdc-slider-hover-handle-color: #ff5a60;
  --mdc-slider-active-track-color: #ff5a60;
  --mdc-slider-inactive-track-color: #ff5a60;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff5a60;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #ff5a60;
  --mat-slider-hover-state-layer-color: rgba(255, 90, 96, 0.05);
  --mat-slider-focus-state-layer-color: rgba(255, 90, 96, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: #0070e0;
  --mat-slider-hover-state-layer-color: rgba(0, 112, 224, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 112, 224, 0.2);
  --mdc-slider-handle-color: #0070e0;
  --mdc-slider-focus-handle-color: #0070e0;
  --mdc-slider-hover-handle-color: #0070e0;
  --mdc-slider-active-track-color: #0070e0;
  --mdc-slider-inactive-track-color: #0070e0;
  --mdc-slider-with-tick-marks-inactive-container-color: #0070e0;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-warn {
  --mat-slider-ripple-color: #bb573e;
  --mat-slider-hover-state-layer-color: rgba(187, 87, 62, 0.05);
  --mat-slider-focus-state-layer-color: rgba(187, 87, 62, 0.2);
  --mdc-slider-handle-color: #bb573e;
  --mdc-slider-focus-handle-color: #bb573e;
  --mdc-slider-hover-handle-color: #bb573e;
  --mdc-slider-active-track-color: #bb573e;
  --mdc-slider-inactive-track-color: #bb573e;
  --mdc-slider-with-tick-marks-inactive-container-color: #bb573e;
  --mdc-slider-with-tick-marks-active-container-color: white;
}

html {
  --mdc-slider-label-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
}

html {
  --mat-menu-item-label-text-color: #44454b;
  --mat-menu-item-icon-color: #44454b;
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: #44454b;
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: #44454b;
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: #44454b;
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ff5a60;
  --mdc-radio-selected-hover-icon-color: #ff5a60;
  --mdc-radio-selected-icon-color: #ff5a60;
  --mdc-radio-selected-pressed-icon-color: #ff5a60;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #0070e0;
  --mdc-radio-selected-hover-icon-color: #0070e0;
  --mdc-radio-selected-icon-color: #0070e0;
  --mdc-radio-selected-pressed-icon-color: #0070e0;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #bb573e;
  --mdc-radio-selected-hover-icon-color: #bb573e;
  --mdc-radio-selected-icon-color: #bb573e;
  --mdc-radio-selected-pressed-icon-color: #bb573e;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #ff5a60;
  --mdc-checkbox-selected-hover-icon-color: #ff5a60;
  --mdc-checkbox-selected-icon-color: #ff5a60;
  --mdc-checkbox-selected-pressed-icon-color: #ff5a60;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff5a60;
  --mdc-checkbox-selected-hover-state-layer-color: #ff5a60;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff5a60;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #0070e0;
  --mdc-checkbox-selected-hover-icon-color: #0070e0;
  --mdc-checkbox-selected-icon-color: #0070e0;
  --mdc-checkbox-selected-pressed-icon-color: #0070e0;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0070e0;
  --mdc-checkbox-selected-hover-state-layer-color: #0070e0;
  --mdc-checkbox-selected-pressed-state-layer-color: #0070e0;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #bb573e;
  --mdc-checkbox-selected-hover-icon-color: #bb573e;
  --mdc-checkbox-selected-icon-color: #bb573e;
  --mdc-checkbox-selected-pressed-icon-color: #bb573e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #bb573e;
  --mdc-checkbox-selected-hover-state-layer-color: #bb573e;
  --mdc-checkbox-selected-pressed-state-layer-color: #bb573e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #ff5a60;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #ff5a60;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 40px;
  --mdc-list-list-item-two-line-container-height: 56px;
  --mdc-list-list-item-three-line-container-height: 80px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 32px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 48px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 64px;
}

html {
  --mdc-list-list-item-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px "Avenir", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 48px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
}

html {
  --mat-paginator-container-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #ff5a60;
  --mat-tab-header-disabled-ripple-color: #e6e6ec;
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(68, 69, 75, 0.6);
  --mat-tab-header-active-label-text-color: #ff5a60;
  --mat-tab-header-active-ripple-color: #ff5a60;
  --mat-tab-header-inactive-ripple-color: #ff5a60;
  --mat-tab-header-inactive-focus-label-text-color: rgba(68, 69, 75, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(68, 69, 75, 0.6);
  --mat-tab-header-active-focus-label-text-color: #ff5a60;
  --mat-tab-header-active-hover-label-text-color: #ff5a60;
  --mat-tab-header-active-focus-indicator-color: #ff5a60;
  --mat-tab-header-active-hover-indicator-color: #ff5a60;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #0070e0;
  --mat-tab-header-disabled-ripple-color: #e6e6ec;
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(68, 69, 75, 0.6);
  --mat-tab-header-active-label-text-color: #0070e0;
  --mat-tab-header-active-ripple-color: #0070e0;
  --mat-tab-header-inactive-ripple-color: #0070e0;
  --mat-tab-header-inactive-focus-label-text-color: rgba(68, 69, 75, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(68, 69, 75, 0.6);
  --mat-tab-header-active-focus-label-text-color: #0070e0;
  --mat-tab-header-active-hover-label-text-color: #0070e0;
  --mat-tab-header-active-focus-indicator-color: #0070e0;
  --mat-tab-header-active-hover-indicator-color: #0070e0;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #bb573e;
  --mat-tab-header-disabled-ripple-color: #e6e6ec;
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(68, 69, 75, 0.6);
  --mat-tab-header-active-label-text-color: #bb573e;
  --mat-tab-header-active-ripple-color: #bb573e;
  --mat-tab-header-inactive-ripple-color: #bb573e;
  --mat-tab-header-inactive-focus-label-text-color: rgba(68, 69, 75, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(68, 69, 75, 0.6);
  --mat-tab-header-active-focus-label-text-color: #bb573e;
  --mat-tab-header-active-hover-label-text-color: #bb573e;
  --mat-tab-header-active-focus-indicator-color: #bb573e;
  --mat-tab-header-active-hover-indicator-color: #bb573e;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #ff5a60;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #0070e0;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #bb573e;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 40px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-tab-header-label-text-size: 16px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 1.5em;
  --mat-tab-header-label-text-weight: 900;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #0070e0;
  --mdc-checkbox-selected-hover-icon-color: #0070e0;
  --mdc-checkbox-selected-icon-color: #0070e0;
  --mdc-checkbox-selected-pressed-icon-color: #0070e0;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #0070e0;
  --mdc-checkbox-selected-hover-state-layer-color: #0070e0;
  --mdc-checkbox-selected-pressed-state-layer-color: #0070e0;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: #44454b;
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #ff5a60;
  --mdc-checkbox-selected-hover-icon-color: #ff5a60;
  --mdc-checkbox-selected-icon-color: #ff5a60;
  --mdc-checkbox-selected-pressed-icon-color: #ff5a60;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #ff5a60;
  --mdc-checkbox-selected-hover-state-layer-color: #ff5a60;
  --mdc-checkbox-selected-pressed-state-layer-color: #ff5a60;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #bb573e;
  --mdc-checkbox-selected-hover-icon-color: #bb573e;
  --mdc-checkbox-selected-icon-color: #bb573e;
  --mdc-checkbox-selected-pressed-icon-color: #bb573e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #bb573e;
  --mdc-checkbox-selected-hover-state-layer-color: #bb573e;
  --mdc-checkbox-selected-pressed-state-layer-color: #bb573e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 32px;
  --mat-checkbox-touch-target-display: none;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-form-field-label-text-line-height: 20px;
  --mdc-form-field-label-text-size: 14px;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: #44454b;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: #44454b;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: #44454b;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: #44454b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #ff5a60;
  --mat-text-button-state-layer-color: #ff5a60;
  --mat-text-button-ripple-color: rgba(255, 90, 96, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #0070e0;
  --mat-text-button-state-layer-color: #0070e0;
  --mat-text-button-ripple-color: rgba(0, 112, 224, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #bb573e;
  --mat-text-button-state-layer-color: #bb573e;
  --mat-text-button-ripple-color: rgba(187, 87, 62, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #ff5a60;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #0070e0;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #bb573e;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #ff5a60;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #0070e0;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #bb573e;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #ff5a60;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #ff5a60;
  --mat-outlined-button-ripple-color: rgba(255, 90, 96, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #0070e0;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #0070e0;
  --mat-outlined-button-ripple-color: rgba(0, 112, 224, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #bb573e;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #bb573e;
  --mat-outlined-button-ripple-color: rgba(187, 87, 62, 0.1);
}

html {
  --mdc-text-button-container-height: 28px;
  --mdc-filled-button-container-height: 28px;
  --mdc-outlined-button-container-height: 28px;
  --mdc-protected-button-container-height: 28px;
  --mat-text-button-touch-target-display: none;
  --mat-filled-button-touch-target-display: none;
  --mat-protected-button-touch-target-display: none;
  --mat-outlined-button-touch-target-display: none;
}

html {
  --mdc-text-button-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-text-button-label-text-size: 16px;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-text-button-label-text-weight: 900;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-filled-button-label-text-size: 16px;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-weight: 900;
  --mdc-filled-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-outlined-button-label-text-size: 16px;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-weight: 900;
  --mdc-outlined-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-protected-button-label-text-size: 16px;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-weight: 900;
  --mdc-protected-button-label-text-transform: none;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #ff5a60;
  --mat-icon-button-state-layer-color: #ff5a60;
  --mat-icon-button-ripple-color: rgba(255, 90, 96, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #0070e0;
  --mat-icon-button-state-layer-color: #0070e0;
  --mat-icon-button-ripple-color: rgba(0, 112, 224, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #bb573e;
  --mat-icon-button-state-layer-color: #bb573e;
  --mat-icon-button-ripple-color: rgba(187, 87, 62, 0.1);
}

html {
  --mat-icon-button-touch-target-display: none;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
}

html {
  --mdc-fab-container-color: white;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #ff5a60;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #0070e0;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #bb573e;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #ff5a60;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #0070e0;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #bb573e;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: none;
  --mat-fab-small-touch-target-display: none;
}

html {
  --mdc-extended-fab-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-extended-fab-label-text-size: 16px;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 900;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #0070e0;
}

html {
  --mdc-snackbar-supporting-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: #44454b;
  --mat-table-row-item-label-text-color: #44454b;
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 48px;
  --mat-table-footer-container-height: 44px;
  --mat-table-row-item-container-height: 44px;
}

html {
  --mat-table-header-headline-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #ff5a60;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #0070e0;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #bb573e;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #ff5a60;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #bdbdbd;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #0070e0;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #bb573e;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-large-size-text-size: 24px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: #44454b;
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: #44454b;
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: #44454b;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: #44454b;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 40px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #ff5a60;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 90, 96, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 90, 96, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 90, 96, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #ff5a60;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 90, 96, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: #44454b;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: #44454b;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: #44454b;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #44454b;
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #0070e0;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 112, 224, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 112, 224, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 112, 224, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 112, 224, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #bb573e;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(187, 87, 62, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(187, 87, 62, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(187, 87, 62, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(187, 87, 62, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #0070e0;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #bb573e;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 16px;
  --mat-datepicker-calendar-body-label-text-weight: 900;
  --mat-datepicker-calendar-period-button-text-size: 16px;
  --mat-datepicker-calendar-period-button-text-weight: 900;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: #44454b;
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: #44454b;
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 40px;
  --mat-expansion-header-expanded-state-height: 56px;
}

html {
  --mat-expansion-header-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #ff5a60;
}
.mat-icon.mat-accent {
  --mat-icon-color: #0070e0;
}
.mat-icon.mat-warn {
  --mat-icon-color: #bb573e;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: #44454b;
  --mat-sidenav-content-background-color: white;
  --mat-sidenav-content-text-color: #44454b;
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #ff5a60;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #ff5a60;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #ff5a60;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: #44454b;
  --mat-stepper-header-error-state-label-text-color: #bb573e;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #bb573e;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #0070e0;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #0070e0;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #0070e0;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #bb573e;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #bb573e;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #bb573e;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 64px;
}

html {
  --mat-stepper-container-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-stepper-header-label-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: white;
  --mat-toolbar-container-text-color: #44454b;
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #ff5a60;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #0070e0;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #bb573e;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 56px;
  --mat-toolbar-mobile-height: 48px;
}

html {
  --mat-toolbar-title-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: #44454b;
}

html {
  --mat-tree-node-min-height: 40px;
}

html {
  --mat-tree-node-text-font: Avenir, Helvetica, Arial, sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

.mat-mdc-menu-content {
  background-color: #ffffff;
  color: #44454b;
}

.mat-mdc-menu-item-text {
  color: #44454b;
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
  background: #e6e6ec;
  color: #313235;
}

.mdc-list-item__primary-text {
  font-size: 14px !important;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.narrow-menu {
  min-width: auto;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel .slider-container {
  width: 160px;
  display: flex;
  padding-left: 16px;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.compact-menu-panel {
  margin-left: 50px;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.short-menu .mat-mdc-menu-content {
  height: 300px;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.pl-menu-panel {
  width: 216px !important;
  border-radius: 0px;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.pl-menu-panel .mat-mdc-menu-content {
  padding: 0px !important;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.pl-menu-panel .mat-mdc-menu-content .pl-menu-content {
  background-color: var(--c-gray-1);
  border-color: var(--c-gray-6);
  border-style: solid;
  border-width: 0px 1px 1px 1px;
  border-radius: 0px 0px 4px 4px;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.pl-menu-panel .mat-mdc-menu-content .pl-menu-content .selected-menu-item {
  background-color: var(--c-gray-3);
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.pl-menu-panel .mat-mdc-menu-content .pl-menu-content .custom-select-options-container {
  color: var(--c-gray-6);
  max-height: 200px;
  overflow-y: auto;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.pl-menu-panel .mat-mdc-menu-content .pl-menu-content .custom-select-options-container .mat-mdc-list-item {
  overflow: hidden;
  padding: 0px;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.pl-menu-panel .mat-mdc-menu-content .pl-menu-content .custom-select-options-container .mat-mdc-list-item .mdc-list-item__content {
  height: 44px;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.pl-menu-panel .mat-mdc-menu-content .pl-menu-content .custom-select-options-container .mat-mdc-list-item .mat-mdc-checkbox.custom-check.mat-mdc-checkbox-checked .mdc-checkbox .mdc-checkbox__background {
  background-color: var(--c-blue-6) !important;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.pl-menu-panel .mat-mdc-menu-content .pl-menu-content .custom-select-options-container .mat-mdc-list-item .mat-mdc-checkbox.custom-check.mat-mdc-checkbox-checked .mdc-checkbox .mdc-checkbox__background .mdc-checkbox__checkmark-path {
  stroke: black !important;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.pl-menu-panel .mat-mdc-menu-content .pl-menu-content .custom-select-options-container .mat-mdc-list-item .mat-mdc-checkbox.custom-check .mdc-checkbox .mdc-checkbox__background {
  border-color: var(--c-gray-5) !important;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.pl-menu-panel .mat-mdc-menu-content .pl-menu-content .custom-select-options-container .mat-mdc-list-item .mat-mdc-checkbox.custom-check label {
  font-weight: var(--fw-normal);
  color: var(--c-gray-6) !important;
  font-size: var(--fs-2) !important;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.pl-menu-panel .mat-mdc-menu-content .pl-menu-content .button-container {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--c-gray-6);
  padding: 6px;
  gap: 6px;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.pl-menu-panel .mat-mdc-menu-content .pl-menu-content .button-container button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: var(--c-gray-2);
  width: 100%;
  border-radius: 4px;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.pl-menu-panel .mat-mdc-menu-content .pl-menu-content .button-container .open-button {
  color: var(--blue-blue-5, #82c1ff);
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.pl-menu-panel .mat-mdc-menu-content .pl-menu-content .button-container .done-button {
  background: var(--blue-blue-5, #82c1ff);
}
.mat-mdc-menu-panel.mat-mdc-menu-panel a.mat-menu-item {
  text-decoration: none;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.user-menu.custom-padding .mat-menu-content {
  padding: 0;
}

.mat-expansion-panel.documentation-appointment-item {
  background: var(--c-gray-1) !important;
  border-color: var(--c-gray-4);
  border-style: solid;
  border-width: 1px 0px 1px 0px;
  border-radius: 0px;
}
.mat-expansion-panel.documentation-appointment-item .mat-expansion-panel-header {
  font-size: 14px;
  padding: var(--s-3);
}
.mat-expansion-panel.documentation-appointment-item .mat-expansion-panel-header .mat-icon {
  line-height: 1;
  font-size: 36px;
  height: 36px;
  width: 36px;
}
.mat-expansion-panel.documentation-appointment-item .mat-expansion-panel-header.mat-expanded {
  height: 48px;
}
.mat-expansion-panel.documentation-appointment-item .mat-expansion-panel-body {
  padding: 0px;
}

.mat-mdc-outlined-button:not(:disabled) {
  border-color: currentColor !important;
}

.mat-primary:not(:disabled).mat-mdc-unelevated-button .mdc-button__label,
.mat-primary:not(:disabled).mat-mdc-unelevated-button .mat-icon, .mat-primary:not(:disabled).mat-mdc-raised-button .mdc-button__label,
.mat-primary:not(:disabled).mat-mdc-raised-button .mat-icon, .mat-primary:not(:disabled).mdc-fab .mdc-button__label,
.mat-primary:not(:disabled).mdc-fab .mat-icon {
  color: #ffffff;
}

.mat-success:not(:disabled).mat-mdc-unelevated-button, .mat-success:not(:disabled).mat-mdc-raised-button, .mat-success:not(:disabled).mat-mdc-fab, .mat-success:not(:disabled).mat-mdc-mini-fab {
  background-color: var(--c-success);
}
.mat-success:not(:disabled).mat-mdc-unelevated-button .mdc-button__label,
.mat-success:not(:disabled).mat-mdc-unelevated-button .mat-icon, .mat-success:not(:disabled).mat-mdc-raised-button .mdc-button__label,
.mat-success:not(:disabled).mat-mdc-raised-button .mat-icon, .mat-success:not(:disabled).mat-mdc-fab .mdc-button__label,
.mat-success:not(:disabled).mat-mdc-fab .mat-icon, .mat-success:not(:disabled).mat-mdc-mini-fab .mdc-button__label,
.mat-success:not(:disabled).mat-mdc-mini-fab .mat-icon {
  color: #ffffff;
}
.mat-success:not(:disabled).mat-mdc-button, .mat-success:not(:disabled).mat-mdc-icon-button, .mat-success:not(:disabled).mat-mdc-outlined-button {
  color: var(--c-success);
}
.mat-success:not(:disabled).mat-mdc-button .mat-button-focus-overlay, .mat-success:not(:disabled).mat-mdc-icon-button .mat-button-focus-overlay, .mat-success:not(:disabled).mat-mdc-outlined-button .mat-button-focus-overlay {
  background-color: var(--c-success);
}

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-outlined-button,
.mat-mdc-raised-button {
  min-height: 44px;
}
.mat-mdc-button.is-rounded,
.mat-mdc-unelevated-button.is-rounded,
.mat-mdc-outlined-button.is-rounded,
.mat-mdc-raised-button.is-rounded {
  border-radius: 44px;
}
.mat-mdc-button.is-small,
.mat-mdc-unelevated-button.is-small,
.mat-mdc-outlined-button.is-small,
.mat-mdc-raised-button.is-small {
  min-height: 28px;
  font-size: var(--fs-2);
  line-height: 1em;
}

.mdc-button .mdc-button__label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  white-space: nowrap;
}

.pl-menu-button.mat-mdc-button {
  padding: 0 var(--s-3) 0 var(--s-4);
}
.pl-menu-button .mdc-button__label {
  width: 100%;
  justify-content: space-between;
}

.mdc-button .mat-icon {
  height: 24px !important;
  width: 24px !important;
  font-size: 24px !important;
}

.mat-mdc-form-field-appearance-outline .mat-form-field-outline,
.mat-mdc-form-field-appearance-outline .mat-hint,
.mat-mdc-form-field-appearance-outline .mat-form-field-label {
  color: #72727b;
}

.mdc-notched-outline__notch label {
  border-bottom: 2px solid rgba(0, 0, 0, 0);
}

.mat-tab-labels .mat-tab-label {
  color: #cccfd4;
  opacity: 1;
}

.mat-tab-group.mat-primary .mat-tab-label.mat-tab-label-active {
  color: #ff5a60;
}

.mat-tab-group.mat-accent .mat-tab-label.mat-tab-label-active {
  color: #0070e0;
}

.mat-tab-header {
  border-bottom-color: #cccfd4;
}

.mat-tab-body {
  background: transparent;
}

.mat-mdc-tab-group .mat-mdc-tab-header,
.mat-mdc-tab-nav-bar .mat-mdc-tab-header {
  border-bottom: 1px solid var(--c-gray-6);
}
.mat-mdc-tab-group .mat-mdc-tab-label-container .mdc-tab-indicator__content,
.mat-mdc-tab-group .mat-mdc-tab-link-container .mdc-tab-indicator__content,
.mat-mdc-tab-nav-bar .mat-mdc-tab-label-container .mdc-tab-indicator__content,
.mat-mdc-tab-nav-bar .mat-mdc-tab-link-container .mdc-tab-indicator__content {
  border-radius: 4px 4px 0 0;
  border-top-width: 4px;
}
.mat-mdc-tab-group.is-compact:not(.light) .mat-mdc-tab-header,
.mat-mdc-tab-nav-bar.is-compact:not(.light) .mat-mdc-tab-header {
  border-bottom: 1px solid var(--c-gray-3);
}
.mat-mdc-tab-group.is-compact:not(.light) .mat-mdc-tab-label-container .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.is-compact:not(.light) .mat-mdc-tab-label-container .mdc-tab__text-label {
  color: var(--c-gray-6);
}
.mat-mdc-tab-group.is-compact .mat-mdc-tab-label-container .mat-mdc-tab-labels,
.mat-mdc-tab-nav-bar.is-compact .mat-mdc-tab-label-container .mat-mdc-tab-labels {
  padding: 0 var(--s-2);
  gap: var(--s-2);
}
.mat-mdc-tab-group.is-compact .mat-mdc-tab-label-container .mdc-tab,
.mat-mdc-tab-nav-bar.is-compact .mat-mdc-tab-label-container .mdc-tab {
  padding: 0;
  width: 48px;
  min-width: 48px;
  font-size: var(--fs-2);
  font-weight: var(--fw-medium);
}
.mat-mdc-tab-group.is-compact .mat-mdc-tab-label-container .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.is-compact .mat-mdc-tab-label-container .mdc-tab__text-label {
  padding-bottom: var(--s-2);
}
.mat-mdc-tab-group.is-compact .mat-mdc-tab-label-container .mdc-tab__content,
.mat-mdc-tab-nav-bar.is-compact .mat-mdc-tab-label-container .mdc-tab__content {
  margin-top: var(--s-3);
}
.mat-mdc-tab-group.is-compact .mat-mdc-tab-label-container .mdc-tab-indicator__content,
.mat-mdc-tab-nav-bar.is-compact .mat-mdc-tab-label-container .mdc-tab-indicator__content {
  border-radius: 3px 3px 0 0;
  border-top-width: 3px;
}
.mat-mdc-tab-group.three-tabs .mat-tab-labels,
.mat-mdc-tab-nav-bar.three-tabs .mat-tab-labels {
  padding: 0 var(--s-1);
}
.mat-mdc-tab-group.light .mdc-tab__text-label,
.mat-mdc-tab-nav-bar.light .mdc-tab__text-label {
  color: var(--c-gray-5);
}

.mat-mdc-checkbox .mdc-checkbox__ripple {
  display: none !important;
  opacity: 0 !important;
}

.mat-mdc-checkbox-ripple {
  display: none !important;
  opacity: 0 !important;
}

.mat-mdc-slide-toggle .mdc-switch--disabled {
  opacity: 1 !important;
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #e6e6ec !important;
}

.mdc-switch + label {
  color: currentColor;
}

.mat-mdc-slide-toggle:not([color], [disabled]) .mdc-switch__handle::before,
.mat-mdc-slide-toggle:not([color], [disabled]) .mdc-switch__handle::after {
  background: #72727b !important;
}
.mat-mdc-slide-toggle:not([color], [disabled]) .mdc-switch__track::before,
.mat-mdc-slide-toggle:not([color], [disabled]) .mdc-switch__track::after {
  background: #cccfd4 !important;
}
.mat-mdc-slide-toggle:not([color], [disabled]) .mat-ripple-element {
  background-color: #cccfd4;
}

.mdc-switch + label {
  font-weight: 700 !important;
  margin-left: 6px !important;
}

.mdc-switch__icons {
  display: none;
}

.mat-mdc-radio-button .mdc-radio:not(.mdc-radio--disabled) .mdc-radio__native-control:not(:enabled:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #50515b !important;
}

.mdc-radio:not(.mdc-radio--disabled) + label {
  color: #50515b;
  cursor: pointer;
}

.mdc-radio--disabled + label {
  color: #cccfd4 !important;
}
.mdc-radio--disabled .mdc-radio__outer-circle,
.mdc-radio--disabled .mdc-radio__inner-circle {
  border-color: #cccfd4 !important;
  opacity: 1 !important;
}

.mdc-form-field > label {
  font-weight: 400;
}

.mat-mdc-radio-button .mdc-radio {
  padding: 0 4px 0 0 !important;
}

.mat-ripple.mat-mdc-focus-indicator {
  display: none;
}

.mat-snack-bar-container {
  background-color: #44454b;
}
.mat-snack-bar-container.snack-bar-is-error {
  background-color: var(--c-error);
}

.mat-mdc-snack-bar-container.pl-snackbar-wrapper .mdc-snackbar__surface {
  background-color: transparent;
  box-shadow: none;
}

.mat-snack-bar-container {
  font-weight: 400;
}
.mat-snack-bar-container.mat-snack-bar-top {
  margin-top: 80px;
}

.mat-dialog-header {
  border-bottom: 1px solid #cccfd4;
}

.mat-dialog-title {
  color: #26292f;
}

.mat-dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mat-dialog-header .mdc-dialog__title {
  padding-left: 0px;
  margin-bottom: 0;
}

.mat-mdc-dialog-container .mat-mdc-dialog-actions {
  min-height: 44px;
  margin-bottom: 0;
  margin-top: var(--s-5);
  padding-top: 0;
  padding-bottom: 0;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  font-size: 14px;
  line-height: 18px;
  padding: 6px 8px;
  max-width: 250px;
}

.mat-button-toggle-group .mat-button-toggle {
  flex: 1;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px;
  font-weight: 900;
  padding: 0 var(--s-2) !important;
}

.cdk-overlay-container {
  /* Set this to be greater than widgets board */
  z-index: 10010;
  user-select: none;
}

html.cdk-global-scrollblock {
  overflow: hidden;
}

.pl-table-paginator.mat-mdc-paginator .mat-mdc-icon-button {
  color: #72727b;
  width: 44px;
  height: 44px;
  padding: 10px;
}
.pl-table-paginator.mat-mdc-paginator .mat-mdc-icon-button .mat-mdc-button-persistent-ripple,
.pl-table-paginator.mat-mdc-paginator .mat-mdc-icon-button .mat-mdc-button-ripple {
  border-radius: 4px;
}
.pl-table-paginator.mat-mdc-paginator .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before, .pl-table-paginator.mat-mdc-paginator .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 1;
  background-color: transparent;
}
.pl-table-paginator.mat-mdc-paginator .mat-mdc-icon-button:active .mat-mdc-paginator-icon, .pl-table-paginator.mat-mdc-paginator .mat-mdc-icon-button:hover .mat-mdc-paginator-icon {
  position: relative;
  z-index: 20;
}
.pl-table-paginator.mat-mdc-paginator .mat-mdc-icon-button:active .mat-mdc-button-ripple, .pl-table-paginator.mat-mdc-paginator .mat-mdc-icon-button:hover .mat-mdc-button-ripple {
  z-index: 10;
  background-color: #f5f7fa;
}
.pl-table-paginator.mat-mdc-paginator .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.pl-table-paginator.mat-mdc-paginator .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.pl-table-paginator.mat-mdc-paginator .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #0070e0;
}
.pl-table-paginator.mat-mdc-paginator .mat-mdc-form-field.mat-primary .mat-mdc-select-arrow {
  transition: transform 225ms;
}
.pl-table-paginator.mat-mdc-paginator .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: #72727b;
  transform: rotate(180deg);
}

.pl-table-paginator-panel .mat-mdc-option.mat-mdc-option-active,
.pl-table-paginator-panel .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: #e6e6ec;
}
.pl-table-paginator-panel .mdc-list-item .mdc-list-item__primary-text,
.pl-table-paginator-panel .mdc-list-item--selected .mdc-list-item__primary-text,
.pl-table-paginator-panel .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #44454b;
}

.mat-mdc-select-panel {
  background-color: #ffffff !important;
}
.mat-mdc-select-panel .mat-mdc-option .mdc-list-item__primary-text {
  color: #44454b !important;
}
.mat-mdc-select-panel .mat-mdc-option:hover {
  background: #e6e6ec;
}

.mat-menu-content {
  padding: 0;
}

.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item.unsafe .mdc-list-item__primary-text {
  color: #ff5a60;
}

.split-button {
  display: flex;
  justify-content: space-between;
}
.split-button .mdc-button__label {
  justify-content: flex-start;
}
.split-button .left {
  width: 100%;
  margin-right: 0 !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.split-button .left.mat-mdc-outlined-button {
  border-right-width: 0;
}
.split-button .left.mat-mdc-raised-button {
  box-shadow: none;
}
.split-button .right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  min-width: auto;
  margin-left: 0 !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.split-button .right.mat-mdc-raised-button {
  box-shadow: none;
}
.split-button .right mat-icon {
  margin: 0;
}
@supports selector(:has(*)) {
  .split-button:has(.mat-mdc-raised-button) {
    border-radius: 4px;
    transition: box-shadow 280ms;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .split-button:has(.mat-mdc-raised-button):active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  .split-button:has(.mat-mdc-raised-button[disabled]) {
    box-shadow: none !important;
  }
}

.mat-slide-toggle-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mat-slide-toggle-group > label {
  font-weight: var(--fw-normal);
}

.radio-bg-button:hover {
  color: var(--c-white);
}
.radio-bg-button:hover .radio-bg-label {
  background-color: var(--c-gray-3);
}
.radio-bg-button .mdc-form-field {
  display: flex;
}
.radio-bg-button label {
  flex: 1;
}
.radio-bg-button .mdc-radio--disabled {
  pointer-events: none;
}
.radio-bg-button .mdc-radio--disabled ~ label {
  filter: grayscale(100%);
}
.radio-bg-button .radio-bg-label {
  display: flex;
  align-items: center;
  flex: 1;
  padding: var(--s-1);
  gap: var(--s-3);
  font-weight: var(--fw-bold);
  white-space: normal;
  border-radius: var(--s-1);
}
.radio-bg-button .radio-bg-preview {
  width: 60px;
  height: 60px;
  border-radius: var(--s-1);
  object-fit: cover;
  background-color: var(--c-border);
}

.chip-transparent {
  background-color: transparent !important;
  border: 1px solid #72727b;
}

a.light {
  display: inline-flex;
  gap: 2px;
  color: #0583ff;
}

.library-tab-nav-bar a.library-tab-link {
  text-decoration: none;
}
.library-tab-nav-bar a.library-tab-link:focus {
  outline: 0rem;
  box-shadow: none;
}

.mat-icon.backwards {
  transform: rotateY(180deg) rotateX(180deg);
}

.pl-default-dialog {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding: 12px 24px;
}
.pl-default-dialog--header {
  display: flex;
  align-items: center;
  height: 44px;
}
.pl-default-dialog--header h2 {
  color: #26292f;
  flex: 1 0 0;
  font-size: 22px;
  font-weight: 500;
  font-style: normal;
  line-height: 28px;
  padding: 0;
}
.pl-default-dialog--header h2::before {
  display: none;
}
.pl-default-dialog--divider {
  height: 1px;
  flex-shrink: 0;
  align-self: stretch;
  background-color: #cccfd4;
  margin: 5px 0;
}
.pl-default-dialog--content {
  padding: 12px 0 !important;
  color: #44454b !important;
}
.pl-default-dialog--actions {
  margin: 0;
  padding: 12px 0;
}

.mat-icon-size-24 {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.mat-icon-size-24 > *[role=img] {
  width: 16px;
  height: 16px;
  font-size: 16px;
}
.mat-icon-size-24 > *[role=img] svg {
  width: 16px;
  height: 16px;
}
.mat-icon-size-24 .mat-mdc-button-touch-target {
  width: 24px !important;
  height: 24px !important;
}

.mat-icon-size-36 {
  width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.mat-icon-size-36 > *[role=img] {
  width: 24px;
  height: 24px;
  font-size: 24px;
}
.mat-icon-size-36 > *[role=img] svg {
  width: 24px;
  height: 24px;
}
.mat-icon-size-36 .mat-mdc-button-touch-target {
  width: 36px !important;
  height: 36px !important;
}

.mat-icon-size-48 {
  width: 48px !important;
  height: 48px !important;
  padding: 0 !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
.mat-icon-size-48 > *[role=img] {
  width: 36px;
  height: 36px;
  font-size: 36px;
}
.mat-icon-size-48 > *[role=img] svg {
  width: 36px;
  height: 36px;
}
.mat-icon-size-48 .mat-mdc-button-touch-target {
  width: 48px !important;
  height: 48px !important;
}

.dark-theme {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: #82c1ff;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #d0e6fd;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #121212;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #121212;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #d0e6fd;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #121212;
  --mat-app-text-color: white;
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #50515b;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #50515b;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #82c1ff;
  --mdc-filled-text-field-focus-active-indicator-color: #82c1ff;
  --mdc-filled-text-field-focus-label-text-color: rgba(130, 193, 255, 0.87);
  --mdc-filled-text-field-container-color: #575862;
  --mdc-filled-text-field-disabled-container-color: #54545e;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #bb573e;
  --mdc-filled-text-field-error-focus-label-text-color: #bb573e;
  --mdc-filled-text-field-error-label-text-color: #bb573e;
  --mdc-filled-text-field-error-caret-color: #bb573e;
  --mdc-filled-text-field-active-indicator-color: rgba(114, 114, 123, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(114, 114, 123, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(114, 114, 123, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #bb573e;
  --mdc-filled-text-field-error-focus-active-indicator-color: #bb573e;
  --mdc-filled-text-field-error-hover-active-indicator-color: #bb573e;
  --mdc-outlined-text-field-caret-color: #82c1ff;
  --mdc-outlined-text-field-focus-outline-color: #82c1ff;
  --mdc-outlined-text-field-focus-label-text-color: rgba(130, 193, 255, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #bb573e;
  --mdc-outlined-text-field-error-focus-label-text-color: #bb573e;
  --mdc-outlined-text-field-error-label-text-color: #bb573e;
  --mdc-outlined-text-field-error-hover-label-text-color: #bb573e;
  --mdc-outlined-text-field-outline-color: rgba(114, 114, 123, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(114, 114, 123, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(114, 114, 123, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #bb573e;
  --mdc-outlined-text-field-error-hover-outline-color: #bb573e;
  --mdc-outlined-text-field-error-outline-color: #bb573e;
  --mat-form-field-focus-select-arrow-color: rgba(130, 193, 255, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #bb573e;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #50515b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(114, 114, 123, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(130, 193, 255, 0.87);
  --mat-select-invalid-arrow-color: rgba(187, 87, 62, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #50515b;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #50515b;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: #a8d4ff;
  --mdc-switch-selected-handle-color: #a8d4ff;
  --mdc-switch-selected-hover-state-layer-color: #a8d4ff;
  --mdc-switch-selected-pressed-state-layer-color: #a8d4ff;
  --mdc-switch-selected-focus-handle-color: #c1e0ff;
  --mdc-switch-selected-hover-handle-color: #c1e0ff;
  --mdc-switch-selected-pressed-handle-color: #c1e0ff;
  --mdc-switch-selected-focus-track-color: #7abbff;
  --mdc-switch-selected-hover-track-color: #7abbff;
  --mdc-switch-selected-pressed-track-color: #7abbff;
  --mdc-switch-selected-track-color: #7abbff;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: #82c1ff;
  --mdc-slider-focus-handle-color: #82c1ff;
  --mdc-slider-hover-handle-color: #82c1ff;
  --mdc-slider-active-track-color: #82c1ff;
  --mdc-slider-inactive-track-color: #82c1ff;
  --mdc-slider-with-tick-marks-inactive-container-color: #82c1ff;
  --mdc-slider-with-tick-marks-active-container-color: #313235;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-ripple-color: #82c1ff;
  --mat-slider-hover-state-layer-color: rgba(130, 193, 255, 0.05);
  --mat-slider-focus-state-layer-color: rgba(130, 193, 255, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #50515b;
  --mat-menu-divider-color: #72727b;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #50515b;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #313235;
  --mdc-checkbox-selected-focus-icon-color: #d0e6fd;
  --mdc-checkbox-selected-hover-icon-color: #d0e6fd;
  --mdc-checkbox-selected-icon-color: #d0e6fd;
  --mdc-checkbox-selected-pressed-icon-color: #d0e6fd;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #d0e6fd;
  --mdc-checkbox-selected-hover-state-layer-color: #d0e6fd;
  --mdc-checkbox-selected-pressed-state-layer-color: #d0e6fd;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-keep-touch-target: false;
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #50515b;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #50515b;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-shadow-color: #000;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: #72727b;
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: #72727b;
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-icon-size: 24px;
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-icon-size: 24px;
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-fab-container-color: #50515b;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-shadow-color: #000;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
  --mdc-fab-small-container-color: #50515b;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shadow-color: #000;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-shadow-color: #000;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #dcdcde;
  --mdc-snackbar-supporting-text-color: rgba(80, 81, 91, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #50515b;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: #72727b;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #82c1ff;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #82c1ff;
  --mat-badge-text-color: #313235;
  --mat-badge-disabled-state-background-color: #595959;
  --mat-badge-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #50515b;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.5);
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.7);
  --mat-legacy-button-toggle-selected-state-background-color: #82c1ff;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #50515b;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #50515b;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #82c1ff;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-standard-button-toggle-disabled-state-background-color: #50515b;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #50515b;
  --mat-standard-button-toggle-divider-color: #72727b;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: #313235;
  --mat-datepicker-calendar-date-selected-state-background-color: #82c1ff;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(130, 193, 255, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #313235;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(130, 193, 255, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(130, 193, 255, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #82c1ff;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(130, 193, 255, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: #72727b;
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-preview-state-outline-color: #72727b;
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-container-background-color: #50515b;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: #72727b;
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #50515b;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: #72727b;
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: rgba(255, 255, 255, 0.7);
  --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.7);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: #72727b;
  --mat-sidenav-container-background-color: #50515b;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #121212;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(175, 174, 164, 0.6);
  --mat-stepper-header-icon-foreground-color: #313235;
  --mat-stepper-header-selected-state-icon-background-color: #82c1ff;
  --mat-stepper-header-selected-state-icon-foreground-color: #313235;
  --mat-stepper-header-done-state-icon-background-color: #82c1ff;
  --mat-stepper-header-done-state-icon-foreground-color: #313235;
  --mat-stepper-header-edit-state-icon-background-color: #82c1ff;
  --mat-stepper-header-edit-state-icon-foreground-color: #313235;
  --mat-stepper-container-color: #50515b;
  --mat-stepper-line-color: #72727b;
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #bb573e;
  --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-error-state-icon-foreground-color: #bb573e;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #cbcbce;
  --mat-toolbar-container-background-color: #313235;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #50515b;
  --mat-tree-node-text-color: white;
}
.dark-theme .mat-accent {
  --mat-option-selected-state-label-text-color: #d0e6fd;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.dark-theme .mat-warn {
  --mat-option-selected-state-label-text-color: #bb573e;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.dark-theme .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #82c1ff;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #121212;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #121212;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #82c1ff;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.dark-theme .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #d0e6fd;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #121212;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #121212;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #d0e6fd;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.dark-theme .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #bb573e;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #121212;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #121212;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #bb573e;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.dark-theme .mat-elevation-z0, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z1, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z2, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z3, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z4, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z5, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z6, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z7, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z8, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z9, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z10, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z11, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z12, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z13, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z14, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z15, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z16, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z17, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z18, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z19, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z20, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z21, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z22, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z23, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z24, .dark-theme .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.dark-theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #82c1ff;
  --mdc-linear-progress-track-color: rgba(130, 193, 255, 0.25);
}
.dark-theme .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #d0e6fd;
  --mdc-linear-progress-track-color: rgba(208, 230, 253, 0.25);
}
.dark-theme .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #bb573e;
  --mdc-linear-progress-track-color: rgba(187, 87, 62, 0.25);
}
.dark-theme .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #d0e6fd;
  --mdc-filled-text-field-focus-active-indicator-color: #d0e6fd;
  --mdc-filled-text-field-focus-label-text-color: rgba(208, 230, 253, 0.87);
  --mdc-outlined-text-field-caret-color: #d0e6fd;
  --mdc-outlined-text-field-focus-outline-color: #d0e6fd;
  --mdc-outlined-text-field-focus-label-text-color: rgba(208, 230, 253, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(208, 230, 253, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #bb573e;
  --mdc-filled-text-field-focus-active-indicator-color: #bb573e;
  --mdc-filled-text-field-focus-label-text-color: rgba(187, 87, 62, 0.87);
  --mdc-outlined-text-field-caret-color: #bb573e;
  --mdc-outlined-text-field-focus-outline-color: #bb573e;
  --mdc-outlined-text-field-focus-label-text-color: rgba(187, 87, 62, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(187, 87, 62, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #50515b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(114, 114, 123, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(208, 230, 253, 0.87);
  --mat-select-invalid-arrow-color: rgba(187, 87, 62, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #50515b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(114, 114, 123, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(187, 87, 62, 0.87);
  --mat-select-invalid-arrow-color: rgba(187, 87, 62, 0.87);
}
.dark-theme .mat-mdc-standard-chip {
  --mdc-chip-container-shape-family: rounded;
  --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: rounded;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.dark-theme .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #65666f;
  --mdc-chip-elevated-selected-container-color: #65666f;
  --mdc-chip-elevated-disabled-container-color: #65666f;
  --mdc-chip-flat-disabled-selected-container-color: #65666f;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: #313235;
  --mdc-chip-elevated-container-color: #82c1ff;
  --mdc-chip-elevated-selected-container-color: #82c1ff;
  --mdc-chip-elevated-disabled-container-color: #82c1ff;
  --mdc-chip-flat-disabled-selected-container-color: #82c1ff;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #313235;
  --mdc-chip-selected-label-text-color: #313235;
  --mdc-chip-with-icon-icon-color: #313235;
  --mdc-chip-with-icon-disabled-icon-color: #313235;
  --mdc-chip-with-icon-selected-icon-color: #313235;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #313235;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #313235;
  --mat-chip-selected-disabled-trailing-icon-color: #313235;
  --mat-chip-selected-trailing-icon-color: #313235;
}
.dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: #313235;
  --mdc-chip-elevated-container-color: #d0e6fd;
  --mdc-chip-elevated-selected-container-color: #d0e6fd;
  --mdc-chip-elevated-disabled-container-color: #d0e6fd;
  --mdc-chip-flat-disabled-selected-container-color: #d0e6fd;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #313235;
  --mdc-chip-selected-label-text-color: #313235;
  --mdc-chip-with-icon-icon-color: #313235;
  --mdc-chip-with-icon-disabled-icon-color: #313235;
  --mdc-chip-with-icon-selected-icon-color: #313235;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #313235;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #313235;
  --mat-chip-selected-disabled-trailing-icon-color: #313235;
  --mat-chip-selected-trailing-icon-color: #313235;
}
.dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #bb573e;
  --mdc-chip-elevated-selected-container-color: #bb573e;
  --mdc-chip-elevated-disabled-container-color: #bb573e;
  --mdc-chip-flat-disabled-selected-container-color: #bb573e;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.dark-theme .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: white;
}
.dark-theme .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #deeefe;
  --mdc-switch-selected-handle-color: #deeefe;
  --mdc-switch-selected-hover-state-layer-color: #deeefe;
  --mdc-switch-selected-pressed-state-layer-color: #deeefe;
  --mdc-switch-selected-focus-handle-color: #e8f3fe;
  --mdc-switch-selected-hover-handle-color: #e8f3fe;
  --mdc-switch-selected-pressed-handle-color: #e8f3fe;
  --mdc-switch-selected-focus-track-color: #cbe3fd;
  --mdc-switch-selected-hover-track-color: #cbe3fd;
  --mdc-switch-selected-pressed-track-color: #cbe3fd;
  --mdc-switch-selected-track-color: #cbe3fd;
}
.dark-theme .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: white;
}
.dark-theme .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #82c1ff;
  --mdc-radio-selected-hover-icon-color: #82c1ff;
  --mdc-radio-selected-icon-color: #82c1ff;
  --mdc-radio-selected-pressed-icon-color: #82c1ff;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #82c1ff;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #d0e6fd;
  --mdc-radio-selected-hover-icon-color: #d0e6fd;
  --mdc-radio-selected-icon-color: #d0e6fd;
  --mdc-radio-selected-pressed-icon-color: #d0e6fd;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #d0e6fd;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #bb573e;
  --mdc-radio-selected-hover-icon-color: #bb573e;
  --mdc-radio-selected-icon-color: #bb573e;
  --mdc-radio-selected-pressed-icon-color: #bb573e;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #bb573e;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-accent {
  --mat-slider-ripple-color: #d0e6fd;
  --mat-slider-hover-state-layer-color: rgba(208, 230, 253, 0.05);
  --mat-slider-focus-state-layer-color: rgba(208, 230, 253, 0.2);
  --mdc-slider-handle-color: #d0e6fd;
  --mdc-slider-focus-handle-color: #d0e6fd;
  --mdc-slider-hover-handle-color: #d0e6fd;
  --mdc-slider-active-track-color: #d0e6fd;
  --mdc-slider-inactive-track-color: #d0e6fd;
  --mdc-slider-with-tick-marks-inactive-container-color: #d0e6fd;
  --mdc-slider-with-tick-marks-active-container-color: #313235;
}
.dark-theme .mat-warn {
  --mat-slider-ripple-color: #bb573e;
  --mat-slider-hover-state-layer-color: rgba(187, 87, 62, 0.05);
  --mat-slider-focus-state-layer-color: rgba(187, 87, 62, 0.2);
  --mdc-slider-handle-color: #bb573e;
  --mdc-slider-focus-handle-color: #bb573e;
  --mdc-slider-hover-handle-color: #bb573e;
  --mdc-slider-active-track-color: #bb573e;
  --mdc-slider-inactive-track-color: #bb573e;
  --mdc-slider-with-tick-marks-inactive-container-color: #bb573e;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
.dark-theme .mdc-list-item__start,
.dark-theme .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #82c1ff;
  --mdc-radio-selected-hover-icon-color: #82c1ff;
  --mdc-radio-selected-icon-color: #82c1ff;
  --mdc-radio-selected-pressed-icon-color: #82c1ff;
}
.dark-theme .mat-accent .mdc-list-item__start,
.dark-theme .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #d0e6fd;
  --mdc-radio-selected-hover-icon-color: #d0e6fd;
  --mdc-radio-selected-icon-color: #d0e6fd;
  --mdc-radio-selected-pressed-icon-color: #d0e6fd;
}
.dark-theme .mat-warn .mdc-list-item__start,
.dark-theme .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #bb573e;
  --mdc-radio-selected-hover-icon-color: #bb573e;
  --mdc-radio-selected-icon-color: #bb573e;
  --mdc-radio-selected-pressed-icon-color: #bb573e;
}
.dark-theme .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #313235;
  --mdc-checkbox-selected-focus-icon-color: #82c1ff;
  --mdc-checkbox-selected-hover-icon-color: #82c1ff;
  --mdc-checkbox-selected-icon-color: #82c1ff;
  --mdc-checkbox-selected-pressed-icon-color: #82c1ff;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #82c1ff;
  --mdc-checkbox-selected-hover-state-layer-color: #82c1ff;
  --mdc-checkbox-selected-pressed-state-layer-color: #82c1ff;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #313235;
  --mdc-checkbox-selected-focus-icon-color: #d0e6fd;
  --mdc-checkbox-selected-hover-icon-color: #d0e6fd;
  --mdc-checkbox-selected-icon-color: #d0e6fd;
  --mdc-checkbox-selected-pressed-icon-color: #d0e6fd;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #d0e6fd;
  --mdc-checkbox-selected-hover-state-layer-color: #d0e6fd;
  --mdc-checkbox-selected-pressed-state-layer-color: #d0e6fd;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #bb573e;
  --mdc-checkbox-selected-hover-icon-color: #bb573e;
  --mdc-checkbox-selected-icon-color: #bb573e;
  --mdc-checkbox-selected-pressed-icon-color: #bb573e;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #bb573e;
  --mdc-checkbox-selected-hover-state-layer-color: #bb573e;
  --mdc-checkbox-selected-pressed-state-layer-color: #bb573e;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #82c1ff;
}
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #82c1ff;
}
.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.dark-theme .mat-mdc-tab-group, .dark-theme .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #82c1ff;
  --mat-tab-header-disabled-ripple-color: #50515b;
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #82c1ff;
  --mat-tab-header-active-ripple-color: #82c1ff;
  --mat-tab-header-inactive-ripple-color: #82c1ff;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #82c1ff;
  --mat-tab-header-active-hover-label-text-color: #82c1ff;
  --mat-tab-header-active-focus-indicator-color: #82c1ff;
  --mat-tab-header-active-hover-indicator-color: #82c1ff;
}
.dark-theme .mat-mdc-tab-group.mat-accent, .dark-theme .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #d0e6fd;
  --mat-tab-header-disabled-ripple-color: #50515b;
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #d0e6fd;
  --mat-tab-header-active-ripple-color: #d0e6fd;
  --mat-tab-header-inactive-ripple-color: #d0e6fd;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #d0e6fd;
  --mat-tab-header-active-hover-label-text-color: #d0e6fd;
  --mat-tab-header-active-focus-indicator-color: #d0e6fd;
  --mat-tab-header-active-hover-indicator-color: #d0e6fd;
}
.dark-theme .mat-mdc-tab-group.mat-warn, .dark-theme .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #bb573e;
  --mat-tab-header-disabled-ripple-color: #50515b;
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #bb573e;
  --mat-tab-header-active-ripple-color: #bb573e;
  --mat-tab-header-inactive-ripple-color: #bb573e;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #bb573e;
  --mat-tab-header-active-hover-label-text-color: #bb573e;
  --mat-tab-header-active-focus-indicator-color: #bb573e;
  --mat-tab-header-active-hover-indicator-color: #bb573e;
}
.dark-theme .mat-mdc-tab-group.mat-background-primary, .dark-theme .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #82c1ff;
  --mat-tab-header-with-background-foreground-color: #313235;
}
.dark-theme .mat-mdc-tab-group.mat-background-accent, .dark-theme .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #d0e6fd;
  --mat-tab-header-with-background-foreground-color: #313235;
}
.dark-theme .mat-mdc-tab-group.mat-background-warn, .dark-theme .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #bb573e;
  --mat-tab-header-with-background-foreground-color: white;
}
.dark-theme .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: white;
}
.dark-theme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #313235;
  --mdc-checkbox-selected-focus-icon-color: #82c1ff;
  --mdc-checkbox-selected-hover-icon-color: #82c1ff;
  --mdc-checkbox-selected-icon-color: #82c1ff;
  --mdc-checkbox-selected-pressed-icon-color: #82c1ff;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #82c1ff;
  --mdc-checkbox-selected-hover-state-layer-color: #82c1ff;
  --mdc-checkbox-selected-pressed-state-layer-color: #82c1ff;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #bb573e;
  --mdc-checkbox-selected-hover-icon-color: #bb573e;
  --mdc-checkbox-selected-icon-color: #bb573e;
  --mdc-checkbox-selected-pressed-icon-color: #bb573e;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #bb573e;
  --mdc-checkbox-selected-hover-state-layer-color: #bb573e;
  --mdc-checkbox-selected-pressed-state-layer-color: #bb573e;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #82c1ff;
  --mat-text-button-state-layer-color: #82c1ff;
  --mat-text-button-ripple-color: rgba(130, 193, 255, 0.1);
}
.dark-theme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #d0e6fd;
  --mat-text-button-state-layer-color: #d0e6fd;
  --mat-text-button-ripple-color: rgba(208, 230, 253, 0.1);
}
.dark-theme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #bb573e;
  --mat-text-button-state-layer-color: #bb573e;
  --mat-text-button-ripple-color: rgba(187, 87, 62, 0.1);
}
.dark-theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #82c1ff;
  --mdc-filled-button-label-text-color: #313235;
  --mat-filled-button-state-layer-color: #313235;
  --mat-filled-button-ripple-color: rgba(49, 50, 53, 0.1);
}
.dark-theme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #d0e6fd;
  --mdc-filled-button-label-text-color: #313235;
  --mat-filled-button-state-layer-color: #313235;
  --mat-filled-button-ripple-color: rgba(49, 50, 53, 0.1);
}
.dark-theme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #bb573e;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #82c1ff;
  --mdc-protected-button-label-text-color: #313235;
  --mat-protected-button-state-layer-color: #313235;
  --mat-protected-button-ripple-color: rgba(49, 50, 53, 0.1);
}
.dark-theme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #d0e6fd;
  --mdc-protected-button-label-text-color: #313235;
  --mat-protected-button-state-layer-color: #313235;
  --mat-protected-button-ripple-color: rgba(49, 50, 53, 0.1);
}
.dark-theme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #bb573e;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #82c1ff;
  --mdc-outlined-button-outline-color: #72727b;
  --mat-outlined-button-state-layer-color: #82c1ff;
  --mat-outlined-button-ripple-color: rgba(130, 193, 255, 0.1);
}
.dark-theme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #d0e6fd;
  --mdc-outlined-button-outline-color: #72727b;
  --mat-outlined-button-state-layer-color: #d0e6fd;
  --mat-outlined-button-ripple-color: rgba(208, 230, 253, 0.1);
}
.dark-theme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #bb573e;
  --mdc-outlined-button-outline-color: #72727b;
  --mat-outlined-button-state-layer-color: #bb573e;
  --mat-outlined-button-ripple-color: rgba(187, 87, 62, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #82c1ff;
  --mat-icon-button-state-layer-color: #82c1ff;
  --mat-icon-button-ripple-color: rgba(130, 193, 255, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #d0e6fd;
  --mat-icon-button-state-layer-color: #d0e6fd;
  --mat-icon-button-ripple-color: rgba(208, 230, 253, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #bb573e;
  --mat-icon-button-state-layer-color: #bb573e;
  --mat-icon-button-ripple-color: rgba(187, 87, 62, 0.1);
}
.dark-theme .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #82c1ff;
  --mat-fab-foreground-color: #313235;
  --mat-fab-state-layer-color: #313235;
  --mat-fab-ripple-color: rgba(49, 50, 53, 0.1);
}
.dark-theme .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #d0e6fd;
  --mat-fab-foreground-color: #313235;
  --mat-fab-state-layer-color: #313235;
  --mat-fab-ripple-color: rgba(49, 50, 53, 0.1);
}
.dark-theme .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #bb573e;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #82c1ff;
  --mat-fab-small-foreground-color: #313235;
  --mat-fab-small-state-layer-color: #313235;
  --mat-fab-small-ripple-color: rgba(49, 50, 53, 0.1);
}
.dark-theme .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #d0e6fd;
  --mat-fab-small-foreground-color: #313235;
  --mat-fab-small-state-layer-color: #313235;
  --mat-fab-small-ripple-color: rgba(49, 50, 53, 0.1);
}
.dark-theme .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #bb573e;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-accent {
  --mdc-circular-progress-active-indicator-color: #d0e6fd;
}
.dark-theme .mat-warn {
  --mdc-circular-progress-active-indicator-color: #bb573e;
}
.dark-theme .mat-badge-accent {
  --mat-badge-background-color: #d0e6fd;
  --mat-badge-text-color: #313235;
}
.dark-theme .mat-badge-warn {
  --mat-badge-background-color: #bb573e;
  --mat-badge-text-color: white;
}
.dark-theme .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: #313235;
  --mat-datepicker-calendar-date-selected-state-background-color: #d0e6fd;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(208, 230, 253, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #313235;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(208, 230, 253, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(208, 230, 253, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(208, 230, 253, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.dark-theme .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #bb573e;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(187, 87, 62, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(187, 87, 62, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(187, 87, 62, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(187, 87, 62, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.dark-theme .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #d0e6fd;
}
.dark-theme .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #bb573e;
}
.dark-theme .mat-icon.mat-primary {
  --mat-icon-color: #82c1ff;
}
.dark-theme .mat-icon.mat-accent {
  --mat-icon-color: #d0e6fd;
}
.dark-theme .mat-icon.mat-warn {
  --mat-icon-color: #bb573e;
}
.dark-theme .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: #313235;
  --mat-stepper-header-selected-state-icon-background-color: #d0e6fd;
  --mat-stepper-header-selected-state-icon-foreground-color: #313235;
  --mat-stepper-header-done-state-icon-background-color: #d0e6fd;
  --mat-stepper-header-done-state-icon-foreground-color: #313235;
  --mat-stepper-header-edit-state-icon-background-color: #d0e6fd;
  --mat-stepper-header-edit-state-icon-foreground-color: #313235;
}
.dark-theme .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #bb573e;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #bb573e;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #bb573e;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.dark-theme .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #82c1ff;
  --mat-toolbar-container-text-color: #313235;
}
.dark-theme .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #d0e6fd;
  --mat-toolbar-container-text-color: #313235;
}
.dark-theme .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #bb573e;
  --mat-toolbar-container-text-color: white;
}
.dark-theme .mat-mdc-menu-content {
  background-color: #50515b;
  color: #ffffff;
}
.dark-theme .mat-mdc-menu-item-text {
  color: #ffffff;
}
.dark-theme .mat-mdc-menu-item:hover:not([disabled]),
.dark-theme .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.dark-theme .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.dark-theme .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: #72727b;
  color: #ffffff;
}
.dark-theme .mat-mdc-select-panel {
  background-color: #50515b !important;
}
.dark-theme .mat-mdc-select-panel .mat-mdc-option .mdc-list-item__primary-text {
  color: #ffffff !important;
}
.dark-theme .mat-mdc-select-panel .mat-mdc-option:hover {
  background: #72727b;
}
.dark-theme .mat-mdc-outlined-button:not(:disabled) {
  border-color: currentColor !important;
}
.dark-theme .mat-primary:not(:disabled).mat-mdc-unelevated-button .mdc-button__label,
.dark-theme .mat-primary:not(:disabled).mat-mdc-unelevated-button .mat-icon, .dark-theme .mat-primary:not(:disabled).mat-mdc-raised-button .mdc-button__label,
.dark-theme .mat-primary:not(:disabled).mat-mdc-raised-button .mat-icon, .dark-theme .mat-primary:not(:disabled).mdc-fab .mdc-button__label,
.dark-theme .mat-primary:not(:disabled).mdc-fab .mat-icon {
  color: #313235;
}
.dark-theme .mat-success:not(:disabled).mat-mdc-unelevated-button, .dark-theme .mat-success:not(:disabled).mat-mdc-raised-button, .dark-theme .mat-success:not(:disabled).mat-mdc-fab, .dark-theme .mat-success:not(:disabled).mat-mdc-mini-fab {
  background-color: var(--c-success);
}
.dark-theme .mat-success:not(:disabled).mat-mdc-unelevated-button .mdc-button__label,
.dark-theme .mat-success:not(:disabled).mat-mdc-unelevated-button .mat-icon, .dark-theme .mat-success:not(:disabled).mat-mdc-raised-button .mdc-button__label,
.dark-theme .mat-success:not(:disabled).mat-mdc-raised-button .mat-icon, .dark-theme .mat-success:not(:disabled).mat-mdc-fab .mdc-button__label,
.dark-theme .mat-success:not(:disabled).mat-mdc-fab .mat-icon, .dark-theme .mat-success:not(:disabled).mat-mdc-mini-fab .mdc-button__label,
.dark-theme .mat-success:not(:disabled).mat-mdc-mini-fab .mat-icon {
  color: #ffffff;
}
.dark-theme .mat-success:not(:disabled).mat-mdc-button, .dark-theme .mat-success:not(:disabled).mat-mdc-icon-button, .dark-theme .mat-success:not(:disabled).mat-mdc-outlined-button {
  color: var(--c-success);
}
.dark-theme .mat-success:not(:disabled).mat-mdc-button .mat-button-focus-overlay, .dark-theme .mat-success:not(:disabled).mat-mdc-icon-button .mat-button-focus-overlay, .dark-theme .mat-success:not(:disabled).mat-mdc-outlined-button .mat-button-focus-overlay {
  background-color: var(--c-success);
}
.dark-theme .mat-mdc-form-field-appearance-outline .mat-form-field-outline,
.dark-theme .mat-mdc-form-field-appearance-outline .mat-hint,
.dark-theme .mat-mdc-form-field-appearance-outline .mat-form-field-label {
  color: #cccfd4;
}
.dark-theme .mat-tab-labels .mat-tab-label {
  color: #cccfd4;
  opacity: 1;
}
.dark-theme .mat-tab-group.mat-primary .mat-tab-label.mat-tab-label-active {
  color: #82c1ff;
}
.dark-theme .mat-tab-group.mat-accent .mat-tab-label.mat-tab-label-active {
  color: #d0e6fd;
}
.dark-theme .mat-tab-header {
  border-bottom-color: #44454b;
}
.dark-theme .mat-tab-body {
  background: #313235;
}
.dark-theme .mat-mdc-slide-toggle .mdc-switch--disabled {
  opacity: 1 !important;
}
.dark-theme .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: #50515b !important;
}
.dark-theme .mdc-switch + label {
  color: #cccfd4;
}
.dark-theme .mat-mdc-slide-toggle:not([color], [disabled]) .mdc-switch__handle::before,
.dark-theme .mat-mdc-slide-toggle:not([color], [disabled]) .mdc-switch__handle::after {
  background: #72727b !important;
}
.dark-theme .mat-mdc-slide-toggle:not([color], [disabled]) .mdc-switch__track::before,
.dark-theme .mat-mdc-slide-toggle:not([color], [disabled]) .mdc-switch__track::after {
  background: #cccfd4 !important;
}
.dark-theme .mat-mdc-slide-toggle:not([color], [disabled]) .mat-ripple-element {
  background-color: #cccfd4;
}
.dark-theme .mat-mdc-radio-button .mdc-radio:not(.mdc-radio--disabled) .mdc-radio__native-control:not(:enabled:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #72727b !important;
}
.dark-theme .mdc-radio:not(.mdc-radio--disabled) + label {
  color: #cccfd4;
  cursor: pointer;
}
.dark-theme .mdc-radio--disabled + label {
  color: #50515b !important;
}
.dark-theme .mdc-radio--disabled .mdc-radio__outer-circle,
.dark-theme .mdc-radio--disabled .mdc-radio__inner-circle {
  border-color: #50515b !important;
  opacity: 1 !important;
}
.dark-theme .mat-snack-bar-container {
  background-color: #44454b;
}
.dark-theme .mat-snack-bar-container.snack-bar-is-error {
  background-color: var(--c-error);
}
.dark-theme .mat-mdc-snack-bar-container.pl-snackbar-wrapper .mdc-snackbar__surface {
  background-color: transparent;
  box-shadow: none;
}
.dark-theme .mat-dialog-header {
  border-bottom: 1px solid #44454b;
}
.dark-theme .mat-dialog-title {
  color: #ffffff;
}
.dark-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.dark-theme .mat-button-toggle-group-appearance-standard {
  border-color: #cccfd4;
}
.dark-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:disabled,
.dark-theme .mat-button-toggle-group-appearance-standard:disabled {
  border-color: #50515b;
}
.dark-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left-color: #cccfd4;
}
.dark-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle-appearance-standard.mat-button-toggle-disabled {
  border-color: #50515b;
}
.dark-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-disabled .mat-button-toggle + .mat-button-toggle {
  border-color: #50515b;
}
.dark-theme .mat-button-toggle-appearance-standard {
  background-color: transparent;
}
.dark-theme .mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled) {
  color: #cccfd4;
}
.dark-theme .mat-button-toggle-appearance-standard.mat-button-toggle-disabled {
  background-color: transparent;
}
.dark-theme .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background-color: #82c1ff;
  color: #44454b;
}
.dark-theme .split-button {
  display: flex;
  justify-content: space-between;
}
.dark-theme .split-button .mdc-button__label {
  justify-content: flex-start;
}
.dark-theme .split-button .left {
  width: 100%;
  margin-right: 0 !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.dark-theme .split-button .left.mat-mdc-outlined-button {
  border-right-width: 0;
}
.dark-theme .split-button .left.mat-mdc-raised-button {
  box-shadow: none;
}
.dark-theme .split-button .right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  min-width: auto;
  margin-left: 0 !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dark-theme .split-button .right.mat-mdc-raised-button {
  box-shadow: none;
}
.dark-theme .split-button .right mat-icon {
  margin: 0;
}
@supports selector(:has(*)) {
  .dark-theme .split-button:has(.mat-mdc-raised-button) {
    border-radius: 4px;
    transition: box-shadow 280ms;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .dark-theme .split-button:has(.mat-mdc-raised-button):active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  .dark-theme .split-button:has(.mat-mdc-raised-button[disabled]) {
    box-shadow: none !important;
  }
}

.cdk-overlay-backdrop.is-dark + * > .cdk-overlay-pane .mat-mdc-menu-content {
  background-color: #50515b;
  color: #ffffff;
}
.cdk-overlay-backdrop.is-dark + * > .cdk-overlay-pane .mat-mdc-menu-item-text {
  color: #ffffff;
}
.cdk-overlay-backdrop.is-dark + * > .cdk-overlay-pane .mat-mdc-menu-item:hover:not([disabled]),
.cdk-overlay-backdrop.is-dark + * > .cdk-overlay-pane .mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.cdk-overlay-backdrop.is-dark + * > .cdk-overlay-pane .mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.cdk-overlay-backdrop.is-dark + * > .cdk-overlay-pane .mat-mdc-menu-item-highlighted:not([disabled]) {
  background: #72727b;
  color: #ffffff;
}

.cdk-overlay-container .cdk-overlay-pane:has(.is-dark) {
  --mat-select-panel-background-color: #50515b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(114, 114, 123, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(130, 193, 255, 0.87);
  --mat-select-invalid-arrow-color: rgba(187, 87, 62, 0.87);
  --mat-option-selected-state-label-text-color: #82c1ff;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.cdk-overlay-container .cdk-overlay-pane:has(.is-dark) .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #50515b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(114, 114, 123, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(208, 230, 253, 0.87);
  --mat-select-invalid-arrow-color: rgba(187, 87, 62, 0.87);
}
.cdk-overlay-container .cdk-overlay-pane:has(.is-dark) .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #50515b;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(114, 114, 123, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(187, 87, 62, 0.87);
  --mat-select-invalid-arrow-color: rgba(187, 87, 62, 0.87);
}
.cdk-overlay-container .cdk-overlay-pane:has(.is-dark) .mat-accent {
  --mat-option-selected-state-label-text-color: #d0e6fd;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.cdk-overlay-container .cdk-overlay-pane:has(.is-dark) .mat-warn {
  --mat-option-selected-state-label-text-color: #bb573e;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.cdk-overlay-container .cdk-overlay-pane:has(.is-dark) .mat-mdc-select-panel {
  background-color: #50515b !important;
}
.cdk-overlay-container .cdk-overlay-pane:has(.is-dark) .mat-mdc-select-panel .mat-mdc-option .mdc-list-item__primary-text {
  color: #ffffff !important;
}
.cdk-overlay-container .cdk-overlay-pane:has(.is-dark) .mat-mdc-select-panel .mat-mdc-option:hover {
  background: #72727b;
}

ngx-skeleton-loader .skeleton-loader.progress {
  box-shadow: unset;
  display: block;
  margin-bottom: 0;
  background: #e6e6ec no-repeat;
}